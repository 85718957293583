import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VisitorResponse } from '../models/visitor';

@Injectable({
  providedIn: 'root'
})
export class VisitorService {

  constructor(private http:HttpClient) { }
  getVisitor() {
    return this.http.get < {
      error: boolean,
      message: string,
      visitorData: VisitorResponse[]
    } > (`${environment.baseUrl}/visitor/get-all-visitor`)
  }

  getTodayVisitor(postData) {
    return this.http.post < {
      error: boolean,
      message: string,
      visitorData: VisitorResponse[]
    } > (`${environment.baseUrl}/visitor/get-today-visitor`,postData)
  }

  addLogout(id,finalDate) {
    return this.http.put < {
      error: boolean,
      message: string
    } > (`${environment.baseUrl}/visitor/add-logout/${id}`,finalDate)
  }

  addLogoutAll(finalDate){
    return this.http.put < {
      error: boolean,
      message: string
    } > (`${environment.baseUrl}/visitor/add-logout-all/`,finalDate)
  }

  remainingVisitor(){
    return this.http.get < {
      error: boolean,
      data
    } > (`${environment.baseUrl}/visitor/remaining-logout/`)
  }

  remainingVisitorByDate(finalDate){
    return this.http.post < {
      error: boolean,
      visitorData: VisitorResponse[]
    } > (`${environment.baseUrl}/visitor/remaining-logout-by-date/`,finalDate)
  }

  // addEmployee(employeeDetails) {
  //  return this.http.post<{error:boolean,message:string,visitorData:VisitorResponse}>(`${environment.baseUrl}/employee/add-employee`,employeeDetails)
  // }

  // updateEmployee(id:string,visitor: VisitorResponse) {
  //   return this.http.put < {
  //     error: boolean,
  //     message:string,
  //     response: VisitorResponse
  //   } > (`${environment.baseUrl}/visitor/edit-visitor/${id}`, visitor)
  // }

  // deletevisitor(id: string) {
  //   return this.http.delete < {
  //     error: boolean,
  //     message: string
  //   } > (`${environment.baseUrl}/visitor/delete-visitor/${id}`)
  // }
  
}
