import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MatSidenavModule} from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { EmployeeComponent } from './employee/employee.component';
import { VisitorsComponent } from './visitors/visitors.component';
import {MatTableModule } from '@angular/material/table';
import {MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';

import { AddUserComponent } from './add-user/add-user.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgOtpInputModule } from  'ng-otp-input';
import { OtpComponent } from './forgot-password/otp/otp.component';
import { EmailComponent } from './forgot-password/email/email.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import {MatSortModule} from '@angular/material/sort';
import { SettingComponent } from './setting/setting.component';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatBadgeModule} from '@angular/material/badge';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidenavComponent,
    HeaderComponent,
    EmployeeComponent,
    VisitorsComponent,
    AddUserComponent,
    PageNotFoundComponent,
    OtpComponent,
    EmailComponent,
    ResetPasswordComponent,
    SettingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatDividerModule,
    MatTableModule,
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgOtpInputModule,
    MatSortModule,
    NgxGalleryModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatBadgeModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      timeOut: 2000,
      preventDuplicates: true,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
