<mat-toolbar>
  <mat-toolbar-row class="justify-content-between">
    <mat-icon> theaters </mat-icon>
    <span>Digitoonz Media & Entertainment Pvt. Ltd.</span>
    <div class="row mr-2 ml-auto">
      <mat-icon class="mt-2 ">account_circle</mat-icon> <small class="mt-1 mr-5"> {{userDisplayName}}</small>
      <button type="button" mat-icon-button matTooltip="Logout" matTooltipPosition="below" (click)="logOut()">
        <mat-icon style="color:#1D447D ;">power_settings_new </mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
  <!-- <button mat-icon-button (click)="toggleSidebar()"><mat-icon>menu</mat-icon></button> -->
</mat-toolbar>
