import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';
import { ToastrService } from 'ngx-toastr';
import { ScreenService } from '../services/screen.service';
import swal from "sweetalert/dist/sweetalert.min.js";
import { ScreenResponse } from '../models/screen';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
})
export class SettingComponent implements OnInit {
  image = [];
  imagePath: string;
  screen: ScreenResponse[];
  previousIndex: number = -1;
  public select:boolean = false;

  @ViewChild("fileUpload", {
    static: false
  }) fileUpload: ElementRef;
  files = [];



  myForm = new FormGroup({
    imagePath: new FormControl('', [Validators.required]),
    // fileSource: new FormControl('', [Validators.required]),
  });
  file: string;

  constructor(
    private screens: ScreenService, 
    private toastr: ToastrService
  ) {
    this.getImages()
  }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.myForm.get('imagePath').setValue(file);
      this.myForm.updateValueAndValidity();
    } else {
      this.toastr.error("Dont use " + file.type + " format");
    }
  }

  onImagePickedToEdit(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.myForm.get('imagePath').setValue(file);
      this.myForm.updateValueAndValidity();
    } else {
      this.toastr.error("Dont use " + file.type + " format");
    }
  }

  disputeFile1: File = null;
  file1: string = null;

  disputeFileUpload1(files: FileList) {
    const reader = new FileReader();
    if (files.item(0)) {
      this.disputeFile1 = this.fileTest(files);
      if (this.disputeFile1) {
        reader.readAsDataURL(this.disputeFile1);
        reader.onload = () => {
          this.file1 = reader.result as string;
        };
      }
    } else {
      this.disputeFile1 = null;
      this.file1 = '';
    }
  }

  fileTest(files: FileList) {
    if (
      files.item(0) &&
      (files.item(0).type.includes('image') || files.item(0).type.includes('pdf'))
    ) {
      return files.item(0);
    } else {
      this.toastr.error('Only image or PDF files are allowed');
      return null;
    }
  }

  ngOnInit(): void {}
  images:ScreenResponse[]
  getImages(){
    this.screens.getScreens().subscribe(
        (res) => {
          if (!res.error) {
            this.images = res.screenData
          } else {
            this.toastr.error(res.message);
          }
        },
        (err) => {
          if (err.status) {
            this.toastr.error(err.error.message);
          } else {
            this.toastr.error('CONNECTION_ERROR');
          }
        }
      );
  }
  submit() {
    console.log(this.myForm.value);
    this.screens.addScreen(this.myForm.value.imagePath).subscribe(
      (res) => {
        if (!res.error) {
          this.toastr.success(res.message);
          this.disputeFile1 = null
          this.file1 = null;
          this.myForm.reset();
          this.getImages()
        } else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        if (err.status) {
          this.toastr.error(err.error.message);
        } else {
          this.toastr.error('CONNECTION_ERROR');
        }
      }
    );
  }
 selects(ScreenResponse: ScreenResponse){
  this.select  = !this.select
  this.screens.selectScreen(ScreenResponse._id).subscribe(
    (res) => {
      if (!res.error) {
        this.toastr.success(res.message);
        this.getImages()
      } else {
        this.toastr.error(res.message);
      }
    },
    (err) => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error('CONNECTION_ERROR');
      }
    }
  );
}

onDelete(ScreenResponse: ScreenResponse) {
  swal({
    title: "Do you want to delete ",
    text: "Once deleted, you will not be able to recover this image",
    icon: "warning",
    buttons: {
      cancel: true,
      ok: true,
    },
    dangerMode: true,
  }).then((willDelete: any) => {
    if (willDelete) {
      this.screens.deleteScreen(ScreenResponse._id).subscribe((res) => {
        if (!res.error) {
          this.images.splice(this.images.indexOf(ScreenResponse), 1);
          swal("Image successfully  deleted!", {
            icon: "success",
          });
          // this.getImages();
        } else {
          this.toastr.error(res.message);
        }
      }, err => {
        this.toastr.error(err.error.message);
      });
    } else {
      swal("Image is not deleted");
    }
  });
}
}
