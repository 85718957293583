<div class="container mt-5">
  <form [formGroup]="myForm" (ngSubmit)="submit()">
    <div class="row">
      <!-- <div class="col-md-3" *ngFor='let url of image'>
        <img [src]="url" height="250px" width="100%"
          style="margin-bottom: 50px;box-shadow: 0px 0px 2px lightgray;border-radius: 5px;">
      </div> -->
      <div class="form-group">
        <label for="file">
          <mat-icon>photo_library</mat-icon>Choose Images
        </label>
        <input id="file" type="file" class="form-control" accept=".jpg, .jpeg ,.png" multiple=""
          (change)="disputeFileUpload1($event.target.files)" (change)="onImagePickedToEdit($event)"
          style="display:none">
      </div>
      <ng-container *ngIf="file1">
        <img class="preview" [src]="
           file1
             ? file1.split(';')[0].split('/')[0] =='data:image'
               ? file1
               : 'updateEmployeeForm.value.imagePath'
             : 'updateEmployeeForm.value.imagePath'
         " alt="file" />
      </ng-container>
    </div>
    <button class="btn addImage" mat-stroked-button [disabled]="myForm.invalid" type="submit">Add Image</button>
  </form>
  <div class="row mt-5" *ngIf="images">
    <div class="col-md-2" *ngFor="let items of images; index as i">
      <div class="card">
        <img class="editImage" [src]="items.imagePath" alt="" srcset="" width="100%">
        <div class="buttons m-2">
          <!-- <button class="float-left" (click)="selects(items)"> <i class="material-icons"  matTooltip="Select" matTooltipPosition="right">done</i></button> -->
          <button class="float-right" (click)="onDelete(items)"><i class="material-icons" matTooltip="Delete"
              matTooltipPosition="left">delete</i></button>
        </div>
        <!-- <div class="select" *ngIf="items.selected==='true'">
                <mat-icon class="rounded-circle">check</mat-icon>
            </div> -->
      </div>
    </div>
  </div>
</div>
<!-- <div class="gallery-wrapper">
  <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
</div> -->
