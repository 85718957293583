<mat-nav-list>
    <div class="logo py4">
        <img src="../../assets/images/logo.png">
    </div>
    <mat-divider></mat-divider>
    <a mat-list-item [routerLink]="['/visitorsList']" [routerLinkActive]="['active']" (keydown.escape)="$event.stopPropagation()">
        <mat-icon> assignment </mat-icon>Visitors
    </a>
    <a mat-list-item [routerLink]="['/employeesList']" [routerLinkActive]="['active']" (keydown.escape)="$event.stopPropagation()">
        <mat-icon> people </mat-icon>Employees
    </a>
    <a mat-list-item [routerLink]="['/admin']" [routerLinkActive]="['active']" (keydown.escape)="$event.stopPropagation()">
        <mat-icon>person_add</mat-icon>User Management
    </a>
    <a mat-list-item [routerLink]="['/setting']" [routerLinkActive]="['active']" (keydown.escape)="$event.stopPropagation()">
        <mat-icon>settings</mat-icon>Setting
    </a>
</mat-nav-list>
