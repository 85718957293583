<!-- <div class="addButton mt-2" style="text-align: right;margin-right: 30px;">
  <button mat-stroked-button color="primary" data-toggle="modal" data-target="#exampleModalCenter"><i class="material-icons">add</i> Add Visitors</button>
</div> -->

<div class="spinner" *ngIf="spinner">
  <mat-spinner diameter="200"></mat-spinner>
</div>

<div class="container-fluid">
  <div class="search-div mt-4">

  <div class="container-fluid mb-2" >
    <div class="search-div mt-4">
  
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="search-form-field " appearance="outline" >
            <mat-icon style="position: 'absolute';right: 0;top: 15; width: 20; height: 20"> search </mat-icon>
            <input matInput name="name" placeholder="Search Visitor" (keyup)="applyFilter($event)" >
          </mat-form-field> 
        </div>
        <!-- <div class="col-md-1">
          <button class=" btn addEmployee mt-1" mat-raised-button  matBadgeColor="warn"  mat-stroked-button style="float: right;" (click)="getVisitor()">
            <i class="material-icons">refresh</i>
          </button>
        </div> -->
        <div class="col-md-4 d-flex">
         <div class="col-md-10">

          <!-- <mat-form-field appearance="outline">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field> -->

          <mat-form-field [formGroup]="range" class="date">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="start" (dateChange)="getVisitor()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>
           <!-- <div class="ml-3 mt-1" >
            <button class="btn btn-outline Search" mat-stroked-button  (click)="change()">Search</button>
           </div> -->
        </div>
        
        <div class="col-md-2">
          <button class=" btn btn-primary addEmployee mt-1" mat-raised-button matBadge="{{remainingVisitorLength}}"  matBadgeColor="warn"  mat-stroked-button color="primary" (click)="logoutAll()" style="float: right;">
            Logout All 
            <i class="material-icons">logout</i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="tables">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <!-- Name Column -->

    <ng-container matColumnDef="SlNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:10%"> Sl No. </th>
      <td mat-cell *matCellDef="let element"> {{element.SlNo}} </td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef >  </th>
      <td mat-cell *matCellDef="let element;" style="width:5%">  <img [src]=element.VisitorImage  onerror="this.src='../../assets/images/user.png'" 
        class="addImage rounded-circle"> </td>
    </ng-container>

    <ng-container matColumnDef="FullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:20%"> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.FullName}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="CompanyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:20%"> Company Name </th>
      <td mat-cell *matCellDef="let element"> {{element.CompanyName}} </td>
    </ng-container>

    <ng-container matColumnDef="loginTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:20%"> Visitor In </th>
      <td mat-cell *matCellDef="let element"> {{element.loginTime}} </td>
    </ng-container>

    <ng-container matColumnDef="logoutTime"> 
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:20%"> Visitor Out </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="!element.logoutTime">
          <button class="addEmployee" mat-stroked-button style="background:#1D447D;color: white;" (click)="visitorOut(element)" data-toggle="modal"
            data-target="#exampleModalCenter" >
            <!-- <i class="material-icons">add</i> -->
             OUT
          </button>
        </div>
        <div *ngIf="element.logoutTime">
          {{element.logoutTime}}
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
</div>
