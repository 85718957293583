
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService } from '../services/employee.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  @ViewChild('triggerForm', {
    static: false
  })
  triggerForm: NgForm;
  loginForm: FormGroup;
  submitted = false;
  passwords;
  show = false;

  constructor(
    private empl: EmployeeService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      emailId: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]),
      password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$")])
    })
  }
  hide = true;
  get passwordInput() {
    return this.loginForm.get('password');
  }
  get loginFormControl() {
    return this.loginForm.controls;
  }

  triggerSubmit() {
    if (!this.triggerForm) {
      console.warn('triggerForm not assigned a value');
    } else {
      if (this.triggerForm.valid) {
        this.triggerForm.ngSubmit.emit();
      }
    }
  }

  loginFormSubmit() {
    this.submitted = true;
    this.empl.onLogin(this.loginForm.value).subscribe((res) => {
      if (!res.error) {
        this.toastr.success(res.message);
        localStorage.setItem('token', res.token);
        localStorage.setItem('userName', res.admin.name)
        localStorage.setItem('dataBaseID', res.admin._id)
        this.router.navigate(['/visitorsList'])
      } else {
        this.toastr.error(res.message)
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  forgotPassword() {
    this.router.navigate(['/email'])
  }
}
