<div class="spinner" *ngIf="spinner">
  <mat-spinner diameter="200"></mat-spinner>
</div>


<div class="container-fluid">
  <div class="search-div mt-4">
    <div class="row">

      <div class="col-md-8">
        <mat-form-field class="search-form-field" appearance="outline">
          <mat-icon class="sticky-top"style="position: 'absolute';right: 0;top: 20px; width: 20; height: 20"> search </mat-icon>
          <input matInput name="name" placeholder="Search User" (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <button class="addEmployee" mat-stroked-button color="primary" data-toggle="modal"
          data-target="#exampleModalCenter" style="float: right;">
          <i class="material-icons">add</i>
          Add User
        </button>
      </div>
    </div>
  </div>

  <div class="tables">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->
      <!-- <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef> SlNo </th>
        <td mat-cell *matCellDef="let element"> {{element._id}} </td>
      </ng-container> -->

      <ng-container matColumnDef="SlNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:10%"> Sl No. </th>
        <td mat-cell *matCellDef="let element"> {{element.SlNo}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:25%"> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name  | titlecase}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="emailId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:25%"> Email Id </th>
        <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:25%"> Designation </th>
        <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button data-toggle="modal" matTooltip="Edit" matTooltipPosition="left"
            data-target="#editEmployeeCenter" (click)="onEdit(element)">
            <mat-icon style="color:#1D447D ;">launch</mat-icon>
          </button>
          <button mat-icon-button color="warn" matTooltip="Delete" matTooltipPosition="right"
            (click)="onDelete(element)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <!-- && employees.data.length==0) -->
    </table>
  </div>
</div>


<!--edit employee-->

<div class="modal fade" id="editEmployeeCenter" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="editEmployeeModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="onClose()" aria-label="Close">
          <span aria-hidden="true" #closeEditModal>&times;</span>
        </button>
      </div>
      <form [formGroup]="updateUserForm" (ngSubmit)="onUpdateEmployee()">
        <div class="card">
          <h1 class="modal-title text-center">Edit User</h1>
          <div class="card-body">

            <div class="form-group">
              <mat-form-field>
                <mat-label>User Name</mat-label>
                <input type="text" matInput formControlName="name" autocomplete="off">
              </mat-form-field>
              <p *ngIf="(updateUserForm.controls.name.touched) && updateUserForm.controls.name.errors?.required"
                class="text-danger">
                Name is required.
              </p>
              <p *ngIf="updateUserForm.get('name').touched && (updateUserForm.get('name').errors?.pattern || updateUserForm.get('name').errors?.minlength) "
                class="text-danger">This is invalid name!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Email Id</mat-label>
                <input type="email" matInput formControlName="emailId">
              </mat-form-field>
              <p *ngIf="(updateUserForm.controls.emailId.touched) && updateUserForm.controls.emailId.errors?.required"
                class="text-danger">
                Email is required.
              </p>
              <!-- <p *ngIf="updateUserForm.get('emailId').touched && updateUserForm.get('emailId').errors?.pattern"
                class="text-danger">This is invalid email address!</p> -->
              <p *ngIf="updateUserForm.get('emailId').touched && (updateUserForm.get('emailId').errors?.email || updateUserForm.get('emailId').errors?.pattern)"
                class="text-danger">This is invalid email address!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Designation</mat-label>
                <input type="text" matInput formControlName="designation">
              </mat-form-field>
              <p *ngIf="( updateUserForm.controls.designation.touched) && updateUserForm.controls.designation.errors?.required"
                class="text-danger">
                Designation is required.
              </p>
              <p *ngIf="updateUserForm.get('designation').touched && (updateUserForm.get('designation').errors?.pattern || updateUserForm.get('designation').errors?.minlength)"
                class="text-danger">This is invalid designation field!</p>
            </div>

            <!-- <div class="form-group">
                <label>Choose Employee Photo</label>
                <input type="file" (change)="onImagePickedToEdit($event)">
            </div> -->
          </div>
        </div>
        <div class="card-footer">
          <div class="m-auto">
            <div class="text-center">
              <button class="btn tech-save" type="submit" [disabled]="updateUserForm.invalid">
                SAVE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!--add employee-->

<div class="modal fade" id="exampleModalCenter" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form [formGroup]="addEmployeeForm" #form="ngForm" (ngSubmit)="onAddEmployee()">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" id="closeModal1" (click)="onClose()" #closeAddModal
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card">
          <h1 class="modal-title text-center">Add User</h1>
          <div class="card-body">
            <div class="form-group">
              <mat-form-field>
                <mat-label>User Name</mat-label>
                <input type="text" matInput formControlName="name">
              </mat-form-field>
              <p *ngIf="(addEmployeeForm.controls.name.touched) && addEmployeeForm.controls.name.errors?.required"
                class="text-danger">
                Name is required.
              </p>
              <p *ngIf="addEmployeeForm.get('name').touched && (addEmployeeForm.get('name').errors?.pattern || addEmployeeForm.get('name').errors?.minlength)"
                class="text-danger">This is invalid name!</p>
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-label>Email Id</mat-label>
                <input type="email" matInput formControlName="emailId">
              </mat-form-field>
              <p *ngIf="(addEmployeeForm.controls.emailId.touched) && addEmployeeForm.controls.emailId.errors?.required"
                class="text-danger">
                Email is required.
              </p>
              <!-- <p *ngIf="addEmployeeForm.get('emailId').touched && addEmployeeForm.get('emailId').errors?.pattern"
              class="text-danger">This is invalid email address!</p> -->
              <p *ngIf="addEmployeeForm.get('emailId').touched && (addEmployeeForm.get('emailId').errors?.email || addEmployeeForm.get('emailId').errors?.pattern)"
                class="text-danger">This is invalid email address!</p>
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer;"
                  [matTooltip]="hide ? 'show password' : 'hide password'" s matTooltipPosition="below">
                  {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
              <p class="help-block text-danger pt-2"
                *ngIf="addEmployeeForm.get('password').hasError('required') && addEmployeeForm.get('password').touched">
                Password is required</p>
              <div
                *ngIf="addEmployeeForm.get('password').touched && addEmployeeForm.get('password').errors?.invalidPassword">
                <small class="text-danger ">Password should have minimum 8 characters</small><br>
                <small class="text-danger">Atleast 1 uppercase letter</small><br>
                <small class="text-danger">Atleast 1 lowercase</small><br>
                <small class="text-danger">Atleast 1 number</small><br>
                <small class="text-danger">Atleast 1 special character</small>
              </div>
              <!-- <p *ngIf="(addEmployeeForm.controls.password.touched) && addEmployeeForm.controls.password.errors?.required" class="text-danger">
                Password is required.
              </p>
              <div *ngIf="addEmployeeForm.get('password').touched && addEmployeeForm.get('password').errors?.pattern"
              class="text-danger">
              <small class="text-danger ">Password should have minimum 8 characters</small><br>
                              <small class="text-danger">Atleast 1 uppercase letter</small><br>
                              <small class="text-danger">Atleast 1 lowercase</small><br>
                              <small class="text-danger">Atleast 1 number</small><br>
                              <small class="text-danger">Atleast 1 special character</small>
            </div> -->
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput formControlName="confirmedPassword">
              </mat-form-field>
              <span class="text-danger"
                *ngIf="(addEmployeeForm.get('confirmedPassword').touched )&& addEmployeeForm.get('confirmedPassword').errors?.required">
                Confirm Password is required
              </span>
              <span class="text-danger"
                *ngIf="addEmployeeForm.get('confirmedPassword').touched && addEmployeeForm.get('confirmedPassword').errors?.passwordMismatch">
                Passwords does not match
              </span>
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-label>Designation</mat-label>
                <input type="text" matInput formControlName="designation">
              </mat-form-field>
              <p *ngIf="( addEmployeeForm.controls.designation.touched) && addEmployeeForm.controls.designation.errors?.required"
                class="text-danger">
                Designation is required.
              </p>
              <p *ngIf="addEmployeeForm.get('designation').touched && (addEmployeeForm.get('designation').errors?.pattern || addEmployeeForm.get('designation').errors?.minlength)"
                class="text-danger">This is invalid designation field!</p>
            </div>
          </div>
          <div class="card-footer">
            <div class="m-auto">
              <div class="text-center">
                <button type="submit" [disabled]="addEmployeeForm.invalid"
                  style="background-color: #1D447D; color: white;" class="btn tech-save">
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #overrideErrorStateMatcher>
  <h3>Override ErrorStateMatcher</h3>
  <form [formGroup]="addEmployeeForm" #form="ngForm" (ngSubmit)="onAddEmployee(form)">
    <div class="modal-header">
      <button type="button" class="close" data-dismiss="modal" (click)="onClose()" id="closeAddModal" #closeAddModal
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <mat-form-field>
            <mat-label>EmailId</mat-label>
            <input matInput formControlName="employeeName" placeholder="name" />
            <mat-error *ngIf="addEmployeeForm.get('employeeName').invalid">
              Name is required.
            </mat-error>
            <mat-error
              *ngIf="addEmployeeForm.get('employeeName').touched && addEmployeeForm.get('employeeName').errors?.pattern"
              class="text-danger">This is invalid name!</mat-error>
          </mat-form-field>
          {{addEmployeeForm.get('employeeName').touched}}
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>EmailId</mat-label>
            <input type="email" matInput formControlName="emailId" required>
          </mat-form-field>
          <div
            *ngIf="(addEmployeeFormControl.emailId.touched || submitted) && addEmployeeFormControl.emailId.errors?.required">
            <p class="text-danger">emailId is required</p>
          </div>
          <p *ngIf="addEmployeeFormControl.emailId.touched && addEmployeeFormControl.emailId.errors?.pattern"
            class="text-danger">This is invalid email address!</p>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Designation</mat-label>
            <input type="text" matInput formControlName="designation" required>
          </mat-form-field>
          <div
            *ngIf="(addEmployeeFormControl.designation.touched || submitted) && addEmployeeFormControl.designation.errors?.required">
            <p class="text-danger">designation is
              required</p>
          </div>
        </div>
        <!-- <div class="form-group">
          <mat-form-field>
            <input type="File" (change)="handleFileInput($event.target.files)" matInput formControlName="image" required>
          </mat-form-field>
          <div *ngIf="(addEmployeeForm.get('image').invalid && addEmployeeForm.get('image').touched)">
            <p *ngIf="addEmployeeForm.get('image').errors?.required" class="text-danger">Employee photo is required
            </p>
          </div>
        </div> -->
      </div>
      <div class="card-footer">
        <div class="m-auto">
          <div class="text-center">
            <button class="btn tech-save" type="submit" (click)="onClose()">
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
