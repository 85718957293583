import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { VisitorResponse } from '../models/visitor';
import { VisitorService } from '../services/visitor.service';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css']
})
export class VisitorsComponent implements OnInit {
  visitors: VisitorResponse[]
  spinner = true;
  table = false;
  name;
  dataSource = new MatTableDataSource([])
  exform: FormGroup;
  todayform: FormGroup
  range: FormGroup;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private visitor: VisitorService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.exform = new FormGroup({
      'finalDate': new FormControl()
    })
    this.todayform = new FormGroup({
      'finalDate': new FormControl()
    })
    this.getTodayVisitor();
    this.remainingVisitor()
  }
  remainingVisitorLength: number = 0
  displayedColumns: string[] = ['SlNo', 'image', 'FullName', 'CompanyName', 'loginTime', 'logoutTime'];

  getTodayVisitor() {
    let date = new Date();
    let d1 = date.toLocaleString("en-CA");
    let d2 = d1.split(", ")
    let finalDate = d2[0]
    this.todayform.get('finalDate').setValue(finalDate);
    this.visitor.getTodayVisitor(this.todayform.value).subscribe((res) => {
      this.spinner = false;
      this.table = true;
      this.visitors = res.visitorData;
      this.visitors.forEach((ele, i) => {
        ele.SlNo = i + 1
      })
      this.dataSource = new MatTableDataSource([
        ...res.visitorData
      ]);
      this.dataSource.sort = this.sort;
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
    // this.remainingVisitorByDate()
  }

  getVisitor() {
    this.table = false
    this.spinner = true
    this.dataSource = new MatTableDataSource([])
    var d1 = new Date(this.range.value.start);
    var start = d1.toLocaleString("en-CA")
    var datas = start.split(', ')
    var finalData = datas[0]
    this.todayform.get('finalDate').setValue(finalData);
    this.visitor.getTodayVisitor(this.todayform.value).subscribe((res) => {
      this.visitors = res.visitorData;
      this.visitors.forEach((ele, i) => {
        ele.SlNo = i + 1
      })
      this.spinner = false;
      this.table = true;
      this.dataSource = new MatTableDataSource([
        ...res.visitorData
      ]);
      this.dataSource.sort = this.sort;
      this.remainingVisitorByDate()
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  remainingVisitorByDate() {
    var d1 = new Date(this.range.value.start);
    var start = d1.toLocaleString("en-CA")
    var datas = start.split(', ')
    var finalData = datas[0]
    this.todayform.get('finalDate').setValue(finalData);
    this.visitor.remainingVisitorByDate(this.todayform.value).subscribe((res) => {
      if (!res.error) {
        this.remainingVisitorLength = res.visitorData.length
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  remainingVisitor() {
    let date = new Date();
    let d1 = date.toLocaleString("en-CA");
    let d2 = d1.split(", ")
    let finalDate = d2[0]
    this.todayform.get('finalDate').setValue(finalDate);
    this.visitor.remainingVisitorByDate(this.todayform.value).subscribe((res) => {
      if (!res.error) {
        this.remainingVisitorLength = res.visitorData.length
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  visitorOut(data) {
    let date = new Date();
    let finalDate = date.toLocaleString("en-CA");
    this.exform.get('finalDate').setValue(finalDate);
    this.visitor.addLogout(data._id, this.exform.value).subscribe((res) => {
      if (!res.error) {
        // this.getTodayVisitor();
        // this.getVisitor();
        --(this.remainingVisitorLength)
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  logoutAll() {
    let date = new Date();
    let finalDate = date.toLocaleString("en-CA");
    this.exform.get('finalDate').setValue(finalDate);
    this.visitor.addLogoutAll(this.exform.value).subscribe((res) => {
      if (!res.error) {
        this.getTodayVisitor();
        this.remainingVisitorLength = 0
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }
}
