import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'swipedown';
  sideBarOpen = true;
  isLogin: boolean = false;
  
  constructor(private router: Router) {
  
  }
  ngOnInit(){
    this.router.events.subscribe((res) => {
      if (
        this.router.url === '/login' ||
        this.router.url === '/email' ||
        this.router.url === `/otp` ||
        this.router.url === '/reset-password'
      ) {
        this.isLogin = false;
      } else {
        this.isLogin = true;
      }
    });
  }
  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
