import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core'; 
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from "sweetalert/dist/sweetalert.min.js";
import { ErrorStateMatcher } from '@angular/material/core';
import { AdminResponse } from '../models/admin';
import { AdminService } from '../services/admin.service';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})

export class AddUserComponent implements OnInit {

  addEmployeeForm: FormGroup;
  updateUserForm: FormGroup;
  selectedUserToEdit: AdminResponse;
  user: AdminResponse[]
  imageData;
  searchKey: string;
  name;
  submitted = false;
  base64result: string
  errorState = false;
  table = false;
  errorMatcher = new CustomErrorStateMatcher();
  spinner = true;
  dataSource = new MatTableDataSource([])

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('form') form;
  @ViewChild('closeEditModal') closeEditModal: ElementRef;
  @ViewChild('closeAddModal') closeAddModal: ElementRef;

  constructor(
    private adminService: AdminService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  //fullname- ^[a-zA-Z][a-zA-Z ]+[a-zA-Z ]$

  ngOnInit(): void {
    this.addEmployeeForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]],
      emailId: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]],
      designation: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$')
      ]],
      password: ['', Validators.compose([Validators.required, this.patternValidator()])],
      confirmedPassword: ['', [Validators.required]],
    }, {
      validator: this.MatchPassword('password', 'confirmedPassword'),
    });

    this.updateUserForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email,
      ]),
      designation: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$')
      ]),
    });
    this.getUser();
  }
  hide = true;
  get passwordInput() {
    return this.addEmployeeForm.get('password');
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }
      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }
      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({
          passwordMismatch: true
        });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  patternValidator() {
    return (control: AbstractControl): {
      [key: string]: any
    } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$');
      const valid = regex.test(control.value);
      return valid ? null : {
        invalidPassword: true
      };
    };
  }

  get addEmployeeFormControl() {
    return this.addEmployeeForm.controls;
  }

  sortData
  displayedColumns: string[] = ['SlNo', 'name', 'emailId', 'designation', 'actions'];
  onReset(addEmployeeForm) {}
  getUser() {
    this.adminService.getAdmin().subscribe((res) => {
      this.spinner = false;
      this.table = true;
      this.user = res.adminData;
      this.user.forEach((ele, i) => {
        ele.SlNo = i + 1
      })
      this.dataSource = new MatTableDataSource([
        ...this.user
      ]);
      this.dataSource.sort = this.sort;
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }


  onAddEmployee() {
    this.adminService.addUser(this.addEmployeeForm.value).subscribe(
      (res) => {
        if (!this.errorState) {
          this.form.resetForm();
        } else {
          this.form.reset();
        }
        this.addEmployeeForm.markAsUntouched();
        if (!res.error) {
          this.submitted = true;
          this.toastr.success(res.message);
          this.addEmployeeForm.reset();
          this.getUser();
        } else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        if (err.status) {
          this.toastr.error(err.error.message);
        } else {
          this.toastr.error('CONNECTION_ERROR');
        }
      }
    );
    this.closeAddModal.nativeElement.click()
  }

  onEdit(user: AdminResponse) {
    this.selectedUserToEdit = {
      ...user
    }
    this.updateUserForm.patchValue({
      name: user.name,
      emailId: user.emailId,
      password: user.password,
      designation: user.designation,
    })
  }

  onClose() {
    if (!this.errorState) {
      this.form.resetForm();
    } else {
      this.form.reset();
    }
    this.addEmployeeForm.markAsUntouched();
  }

  onUpdateEmployee() {
    this.submitted = true;
    this.adminService.updateUser(this.selectedUserToEdit._id, this.updateUserForm.value)
      .subscribe((res) => {
        if (!res.error) {

          if (localStorage.getItem('dataBaseID') == res.response._id) {
            localStorage.setItem('userName', res.response.name);
          }
          this.onClose();
          this.user.splice(
            this.user.findIndex(
              (element) => element._id === res.response._id
            ),
            1,
            res.response
          );
          this.toastr.success(res.message);
          this.getUser();
        } else {
          this.toastr.error(res.message);
        }
      }, err => {
        if (err.status) {
          this.toastr.error(err.error.message);
        } else {
          this.toastr.error("CONNECTION_ERROR");
        }
      });
    this.closeEditModal.nativeElement.click();
  }

  onDelete(users: AdminResponse) {
    swal({
      title: "Do you want to delete " + users.name,
      text: "Once deleted, you will not be able to recover this user",
      icon: "warning",
      buttons: {
        cancel: true,
        ok: true,
      },
      dangerMode: true,
    }).then((willDelete: any) => {
      if (willDelete) {
        this.adminService.deleteEmployee(users._id).subscribe((res) => {
          if (!res.error) {
            this.user.splice(this.user.indexOf(users), 1);
            swal("User successfully  deleted!", {
              icon: "success",
            });
            this.getUser()
          } else {
            this.toastr.error(res.message);
          }
        }, err => {
          this.toastr.error(err.error.message);
        });
      } else {
        swal("User is not deleted");
      }
    });
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: NgForm | FormGroupDirective | null) {
    return control && control.invalid && control.touched;
  }
}
