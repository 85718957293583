<div class="spinner" *ngIf="spinner">
  <mat-spinner diameter="200"></mat-spinner>
</div>

<div class="container-fluid">
  <div class="search-div mt-4">
    <div class="row">
      <div class="col-md-8">
        <mat-form-field class="search-form-field sticky-top" appearance="outline">
          <mat-icon  style="position: 'absolute';right: 0;top: 15; width: 20; height: 20"> search </mat-icon>
          <input matInput name="employeeName" placeholder="Search Employee" (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <button class="addEmployee" mat-stroked-button color="primary" data-toggle="modal"
          data-target="#exampleModalCenter" style="float: right;"><i class="material-icons">add</i> Add
          Employee</button>
      </div>
    </div>
  </div>
  <div class="tables">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->
      <!-- <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef> SlNo </th>
        <td mat-cell *matCellDef="let element"> {{element._id}} </td>
      </ng-container> -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef style="width:6%"> </th>
        <td mat-cell *matCellDef="let element;">

          <img [src]=element.imagePath onerror="this.src='../../assets/images/user.png'"
            class="addImage rounded-circle">

        </td>
      </ng-container>

      <ng-container matColumnDef="employeeID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:15%">Employee Id </th>
        <td mat-cell *matCellDef="let element; index as i"> {{element.employeeID}}</td>
      </ng-container>



      <!-- Name Column -->
      <ng-container matColumnDef="employeeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:20%"> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.employeeName}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="emailId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:25%">Email Id </th>
        <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:22%"> Designation </th>
        <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width:20%"> Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button data-toggle="modal" matTooltip="Edit" matTooltipPosition="left"
            data-target="#editEmployeeCenter" (click)="onEdit(element)">
            <mat-icon style="color:#1D447D ;">launch</mat-icon>
          </button>
          <button mat-icon-button color="warn" matTooltip="Delete" matTooltipPosition="right"
            (click)="onDelete(element)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <!-- && employees.data.length==0) -->
    </table>
  </div>
</div>

<!--edit employee-->

<div class="modal fade" id="editEmployeeCenter" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="editEmployeeModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="onClose()" #closeEditModal>&times;</span>
        </button>
      </div>
      <form [formGroup]="updateEmployeeForm" (ngSubmit)="onUpdateEmployee()">
        <div class="card">
          <h1 class="modal-title text-center mb-0">Edit Employee</h1>
          <div class="card-body">
            <div class="form-group text-center">
              <ng-container *ngIf="!file1">
                <img [src]="updateEmployeeForm.value.imagePath" onerror="this.src='../../assets/images/user.png'"
                  alt="imagePath" class="editImage rounded-circle">
              </ng-container>

              <ng-container *ngIf="file1">
                <img class="editImage" [src]="
                   file1
                     ? file1.split(';')[0].split('/')[0] =='data:image'
                       ? file1
                       : 'updateEmployeeForm.value.imagePath'
                     : 'updateEmployeeForm.value.imagePath'
                 " alt="file" />
              </ng-container>


            </div>
            <div class="form-group" style="margin-top: 40px;">
              <mat-form-field>
                <mat-label>Employee Name</mat-label>
                <input type="text" matInput formControlName="employeeName" autocomplete="off">
              </mat-form-field>
              <p *ngIf="(updateEmployeeForm.controls.employeeName.touched) && updateEmployeeForm.controls.employeeName.errors?.required"
                class="text-danger">
                Name is required.
              </p>
              <p *ngIf="updateEmployeeForm.get('employeeName').touched && (updateEmployeeForm.get('employeeName').errors?.pattern || updateEmployeeForm.get('employeeName').errors?.minlength)"
                class="text-danger">This is invalid name!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Email ID</mat-label>
                <input type="email" matInput formControlName="emailId">
              </mat-form-field>
              <p *ngIf="(updateEmployeeForm.controls.emailId.touched) && updateEmployeeForm.controls.emailId.errors?.required"
                class="text-danger">
                Email is required.
              </p>
              <p *ngIf="addEmployeeForm.get('emailId').touched && (addEmployeeForm.get('emailId').errors?.email || addEmployeeForm.get('emailId').errors?.pattern)"
                class="text-danger">This is invalid email address!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Designation</mat-label>
                <input type="text" matInput formControlName="designation">
              </mat-form-field>
              <p *ngIf="( updateEmployeeForm.controls.designation.touched) && updateEmployeeForm.controls.designation.errors?.required"
                class="text-danger">
                Designation is required.
              </p>
              <p *ngIf="updateEmployeeForm.get('designation').touched && (updateEmployeeForm.get('designation').errors?.pattern || updateEmployeeForm.get('designation').errors?.minlength )"
                class="text-danger">This is invalid designation field!</p>
            </div>

            <div class="form-group">
              <label>Choose Employee Photo</label>
              <!-- <input type="file" #EditemployeePhoto (change)="onImagePickedToEdit($event)" accept=".jpg, .jpeg ,.png"> -->
              <input type="file" #EditemployeePhoto accept=".jpg, .jpeg ,.png, images/jpg" id="file1"
                (change)="disputeFileUpload1($event.target.files)" (change)="onImagePickedToEdit($event)" />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="m-auto">
            <div class="text-center">
              <button class="btn tech-save" type="submit" [disabled]="updateEmployeeForm.invalid">
                SAVE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!--add employee-->

<div class="modal fade" id="exampleModalCenter" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form [formGroup]="addEmployeeForm" #form="ngForm" (ngSubmit)="onAddEmployee()">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" id="closeModal1" (click)="onClose()" #closeAddModal
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card">
          <h1 class="modal-title text-center">Add Employee</h1>
          <div class="card-body">

            <div class="form-group">
              <mat-form-field>
                <mat-label>Employee ID</mat-label>
                <input type="text" matInput formControlName="employeeID">
              </mat-form-field>
              <p *ngIf="(addEmployeeForm.controls.employeeID.touched) && addEmployeeForm.controls.employeeID.errors?.required"
                class="text-danger">
                Employee ID is required.
              </p>
              <p *ngIf="addEmployeeForm.get('employeeID').touched && (addEmployeeForm.get('employeeID').errors?.pattern || addEmployeeForm.get('employeeID').errors?.minlength || addEmployeeForm.get('employeeID').errors?.maxlength)"
                class="text-danger">This is invalid Employee ID!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Employee Name</mat-label>
                <input type="text" matInput formControlName="employeeName">
              </mat-form-field>
              <p *ngIf="(addEmployeeForm.controls.employeeName.touched) && addEmployeeForm.controls.employeeName.errors?.required"
                class="text-danger">
                Name is required.
              </p>
              <p *ngIf="addEmployeeForm.get('employeeName').touched && (addEmployeeForm.get('employeeName').errors?.pattern || addEmployeeForm.get('employeeName').errors?.minlength)"
                class="text-danger">This is invalid name!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Email Id</mat-label>
                <input type="email" matInput formControlName="emailId">
              </mat-form-field>
              <p *ngIf="(addEmployeeForm.controls.emailId.touched) && addEmployeeForm.controls.emailId.errors?.required"
                class="text-danger">
                Email is required.
              </p>
              <!-- <p *ngIf="addEmployeeForm.get('emailId').touched && addEmployeeForm.get('emailId').errors?.pattern"
              class="text-danger">This is invalid email address!</p> -->
              <p *ngIf="addEmployeeForm.get('emailId').touched && (addEmployeeForm.get('emailId').errors?.email || addEmployeeForm.get('emailId').errors?.pattern)"
                class="text-danger">This is invalid email address!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <mat-label>Designation</mat-label>
                <input type="text" matInput formControlName="designation">
              </mat-form-field>
              <p *ngIf="( addEmployeeForm.controls.designation.touched) && addEmployeeForm.controls.designation.errors?.required"
                class="text-danger">
                Designation is required.
              </p>
              <p *ngIf="addEmployeeForm.get('designation').touched && (addEmployeeForm.get('designation').errors?.pattern || addEmployeeForm.get('designation').errors?.minlength)"
                class="text-danger">This is invalid designation field!</p>
            </div>

            <div class="form-group">
              <mat-form-field>
                <label>Choose Employee Photo</label>
                <input type="File" #employeePhoto (change)="onImagePicked($event)" accept=".jpg, .jpeg ,.png" matInput>
              </mat-form-field>
              <div *ngIf="(addEmployeeForm.get('imagePath').invalid && addEmployeeForm.get('imagePath').touched)">
                <p *ngIf="addEmployeeForm.get('imagePath').errors?.required" class="text-danger">Employee photo is
                  required
                </p>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <div class="m-auto">
              <div class="text-center">
                <button style="background-color: #1D447D; color: white;" class="btn tech-save" type="submit"
                  [disabled]="addEmployeeForm.invalid">
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
