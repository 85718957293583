<div class="container">
  <div class="contnet ">
    <div class="row">
      <div class="col-md-8 offset-md-2 ">
        <div class="login">
          <mat-card>
            <form class="exampleForm" [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()">
              <div class="form-group">
                <label for="password" class="control-label">Password:</label>
                <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
                  <input type="password" matInput formControlName="password" title="Please enter your password"
                    [type]="hide ? 'password' : 'text'">
                  <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer;"
                    [matTooltip]="hide ? 'show password' : 'hide password'" matTooltipPosition="below">
                    {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <p class="help-block text-danger pt-2"
                  *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">Password
                  must be required</p>
                <div *ngIf="loginForm.get('password').touched && loginForm.get('password').errors?.invalidPassword">
                  <small class="text-danger ">Password should have minimum 8 characters</small><br>
                  <small class="text-danger">Atleast 1 uppercase letter</small><br>
                  <small class="text-danger">Atleast 1 lowercase</small><br>
                  <small class="text-danger">Atleast 1 number</small><br>
                  <small class="text-danger">Atleast 1 special character</small>
                </div>
              </div>
              <div class="form-group">
                <label for="confirmedPassword" class="control-label">Confirm Password:</label>
                <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
                  <input type="password" matInput formControlName="confirmedPassword"
                    title="Please re-enter your password" [type]="hide2 ? 'password' : 'text'">
                  <mat-icon matSuffix (click)="hide2 = !hide2" style="cursor: pointer;"
                    [matTooltip]="hide2 ? 'show password' : 'hide password'" matTooltipPosition="below">
                    {{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <span class="text-danger"
                  *ngIf="(loginForm.get('confirmedPassword').touched )&& loginForm.get('confirmedPassword').errors?.required">
                  Confirm Password is required
                </span>
                <span class="text-danger"
                  *ngIf="loginForm.get('confirmedPassword').touched && loginForm.get('confirmedPassword').errors?.passwordMismatch">
                  Passwords does not match
                </span>
              </div>
              <div class="form-group text-center">
                <button mat-raised-button [disabled]="loginForm.invalid" class="loginButton">Update</button>
              </div>
            </form>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
