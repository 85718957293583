import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core'; 
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from "sweetalert/dist/sweetalert.min.js";
import { EmployeeResponse } from '../models/employeeResponse';
import { EmployeeService } from '../services/employee.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css'],
})
export class EmployeeComponent implements OnInit {
  addEmployeeForm: FormGroup;
  updateEmployeeForm: FormGroup;
  selectedEmployeeToEdit: EmployeeResponse;
  employees: EmployeeResponse[];
  imageData;
  searchKey: string;
  employeeName;
  submitted = false;
  base64result: string
  errorState = false;
  spinner = true;
  table = false;
  disputeFile1: File = null;
  file1: string = null;
  imagePath: string = "";
  errorMatcher = new CustomErrorStateMatcher();
  dataSource = new MatTableDataSource([])

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('form') form;
  @ViewChild('closeEditModal') closeEditModal: ElementRef;
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('employeePhoto') employeePhoto: ElementRef;
  @ViewChild('EditemployeePhoto') EditemployeePhoto: ElementRef;

  constructor(
    private employeeService: EmployeeService,
    private toastr: ToastrService,
  ) {}
  
  //^[a-zA-Z]{1,}(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$
  //^[a-zA-Z][a-zA-Z ]+[a-zA-Z ]$
  //[A-Z]{4}[0-9]{6}
  ngOnInit(): void {
    this.addEmployeeForm = new FormGroup({
      employeeID: new FormControl('', [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(15),
        Validators.pattern("^[A-Za-z0-9]*$")
      ]),
      employeeName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]),
      designation: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$')
      ]),
      imagePath: new FormControl(null)
    });

    this.updateEmployeeForm = new FormGroup({
      employeeName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]),
      designation: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$')
      ]),
      imagePath: new FormControl(null)
    });
    this.getEmployee();
  }
  get addEmployeeFormControl() {
    return this.addEmployeeForm.controls;
  }

  displayedColumns: string[] = ['employeeID', 'image', 'employeeName', 'emailId', 'designation', 'actions'];

  onReset(addEmployeeForm) {}

  getEmployee() {
    this.employeeService.getEmployee().subscribe((res) => {
      this.spinner = false;
      this.table = true;
      this.employees = res.employeeData;
      this.dataSource = new MatTableDataSource([
        ...res.employeeData
      ]);
      this.dataSource.sort = this.sort;
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.addEmployeeForm.get('imagePath').setValue(file);
      this.addEmployeeForm.updateValueAndValidity();
    } else {
      this.toastr.error("Dont use " + file.type + " format");
    }
  }

  onImagePickedToEdit(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.updateEmployeeForm.get('imagePath').setValue(file);
      this.addEmployeeForm.updateValueAndValidity();
    } else {
      this.toastr.error("Dont use " + file.type + " format");
    }
  }

  onAddEmployee() {
    this.employeeService.addEmployee(this.addEmployeeForm.value.employeeName, this.addEmployeeForm.value.emailId, this.addEmployeeForm.value.designation, this.addEmployeeForm.value.imagePath, this.addEmployeeForm.value.employeeID).subscribe(
      (res) => {
        this.employeePhoto.nativeElement.value = null
        if (!this.errorState) {
          this.form.resetForm();
        } else {
          this.form.reset();
        }
        this.addEmployeeForm.markAsUntouched();
        if (!res.error) {
          this.toastr.success(res.message);
          this.addEmployeeForm.reset()
          this.closeAddModal.nativeElement.click()
          this.getEmployee()
        } else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        if (err.status) {
          this.toastr.error(err.error.message);
        } else {
          this.toastr.error('CONNECTION_ERROR');
        }
      }
    );
  }

  onEdit(employee: EmployeeResponse) {
    this.selectedEmployeeToEdit = {
      ...employee
    }
    this.updateEmployeeForm.patchValue({
      employeeName: employee.employeeName,
      emailId: employee.emailId,
      designation: employee.designation,
      imagePath: employee.imagePath
    })
  }

  onClose() {
    if (!this.errorState) {
      this.form.resetForm();
    } else {
      this.form.reset();
    }
    this.addEmployeeForm.markAsUntouched();
  }

  onUpdateEmployee() {
    this.submitted = true;
    this.employeeService.updateEmployee(this.selectedEmployeeToEdit._id, this.updateEmployeeForm.value.employeeName, this.updateEmployeeForm.value.emailId, this.updateEmployeeForm.value.designation, this.updateEmployeeForm.value.imagePath)
      .subscribe((res) => {
        this.EditemployeePhoto.nativeElement.value = null
        if (!res.error) {
          this.closeEditModal.nativeElement.click();
          this.employees.splice(
            this.employees.findIndex(
              (element) => element._id === res.response._id
            ),
            1,
            res.response
          );
          this.toastr.success(res.message);

          this.getEmployee();
          this.file1 = null;
        } else {
          this.toastr.error(res.message);
        }
      }, err => {
        if (err.status) {
          this.toastr.error(err.error.message);
        } else {
          this.toastr.error("CONNECTION_ERROR");
        }
      });
  }

  onDelete(employee: EmployeeResponse) {
    swal({
      title: "Do you want to delete " + employee.employeeName,
      text: "Once deleted, you will not be able to recover this employee",
      icon: "warning",
      buttons: {
        cancel: true,
        ok: true,
      },
      dangerMode: true,
    }).then((willDelete: any) => {
      if (willDelete) {
        this.employeeService.deleteEmployee(employee._id).subscribe((res) => {
          if (!res.error) {
            this.employees.splice(this.employees.indexOf(employee), 1);
            swal("Employee successfully  deleted!", {
              icon: "success",
            });
            this.getEmployee()
          } else {
            this.toastr.error(res.message);
          }
        }, err => {
          this.toastr.error(err.error.message);
        });
      } else {
        swal("Employee is not deleted");
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  fileTest(files: FileList) {
    if (
      files.item(0) &&
      (files.item(0).type.includes('image') || files.item(0).type.includes('pdf'))
    ) {
      return files.item(0);
    } else {
      this.toastr.error('Only image or PDF files are allowed');
      return null;
    }
  }
  
  disputeFileUpload1(files: FileList) {
    const reader = new FileReader();
    if (files.item(0)) {
      this.disputeFile1 = this.fileTest(files);
      if (this.disputeFile1) {
        reader.readAsDataURL(this.disputeFile1);
        reader.onload = () => {
          this.file1 = reader.result as string;
        };
      }
    } else {
      this.disputeFile1 = null;
      this.file1 = '';
    }
  }
}
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: NgForm | FormGroupDirective | null) {
    return control && control.invalid && control.touched;
  }
}
