import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUserComponent } from './add-user/add-user.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmailComponent } from './forgot-password/email/email.component';
import { OtpComponent } from './forgot-password/otp/otp.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SettingComponent } from './setting/setting.component';
import { VisitorsComponent } from './visitors/visitors.component';

const routes: Routes = [{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'employeesList',
    component: EmployeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'visitorsList',
    component: VisitorsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AddUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'email',
    component: EmailComponent
  },
  {
    path: 'otp',
    component: OtpComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path:'setting',
    component:SettingComponent
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: "/404"
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
