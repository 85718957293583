import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AdminResponse } from '../models/admin';
import { EmployeeResponse } from '../models/employeeResponse';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private http: HttpClient, 
    private router: Router
  ) {}

  onLogin(loginCredrntials) {
    return this.http.post < {
      error: boolean,
      message: string,
      token: string,
      admin: AdminResponse
    } > (`${environment.baseUrl}/admin/login`, loginCredrntials)
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }
  logOut() {
    this.router.navigate(['/login']);
    localStorage.clear();
  }

  getEmployee() {
    return this.http.get < {
      error: boolean,
      message: string,
      employeeData: EmployeeResponse[]
    } > (`${environment.baseUrl}/employee/get-all-employee`)
  }

  addEmployee(employeeName: string, emailId: string, designation: string, imagePath: File,employeeID:string) {
    const postData = new FormData();
    postData.append("employeeName", employeeName);
    postData.append("emailId", emailId);
    postData.append("designation", designation);
    postData.append("imagePath", imagePath);
    postData.append("employeeID",employeeID)
    return this.http.post < {
      error: boolean,
      message: string,
      employeedata: EmployeeResponse
    } > (`${environment.baseUrl}/employee/add-employee`, postData)
  }

  updateEmployee(id: string, employeeName: string, emailId: string, designation: string, imagePath: File) {
    const postData = new FormData();
    postData.append("employeeName", employeeName);
    postData.append("emailId", emailId);
    postData.append("designation", designation);
    postData.append("imagePath", imagePath);
    return this.http.put < {
      error: boolean,
      message: string,
      response: EmployeeResponse
    } > (`${environment.baseUrl}/employee/edit-employee/${id}`, postData)
  }

  deleteEmployee(id: string) {
    return this.http.delete < {
      error: boolean,
      message: string
    } > (`${environment.baseUrl}/employee/delete-employee/${id}`)
  }
}
